import React from 'react'
import { Outlet , Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore , {Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import Testimonial from '../../components/testimonial/testimonial.component'
import Brands from '../../components/brands/brands.componenet'
import SignUp from '../../components/signup/signup.component';
import Footer from '../../components/footer/footer.componenet';




SwiperCore.use([Pagination]);



function Home() {
  return (
    <>
            
    {/* <div className="page-loading active">
        <div className="page-loading-inner">
        <div className="page-spinner"></div><span>Loading</span>
        </div>
    </div>  */}
    <Outlet/>
    {/* Page wrapper for sticky footer */}
    <main className="page-wrapper overflow-hidden">

    
    {/* Hero */}
    <section className="position-relative" >
    <div className="position-relative bg-dark zindex-4 pt-lg-3 pt-xl-5  card-body index-hero-img">
        <img src="assets/img/landingcogmatic/vector.png" className="bg-size-cover position-absolute" alt="" />
        {/* Text */}
        <div className="container zindex-5 hero-text">
        <div className="row justify-content-center text-center pt-4 pb-sm-2 py-lg-5">
            <div className="col-xl-10 col-lg-9 col-md-10 py-5 ">
            <h1 className="display-6 text-light pt-sm-2 pt-3 pb-1 ">Enterprise-AI Done Right</h1>
            <h2 className=" text-light hero-text pt-sm-2 pb-1 ">Develop production-ready AI solutions in the shortest time</h2>
            <div className="row justify-content-center text-center">
            <div className="col-xl-6  ">
                <p className="fs-lg text-light opacity-70 pb-2 pb-sm-0 mb-4 mb-sm-5 text-center">
                    Cogmatic develops bespoke AI Solutions and Enterprise Software. We are experts at bringing custom machine learning solutions out of the lab and into the real world.
                </p>
            </div>
            </div>
            </div>
        </div>
        </div>

        {/* Bottom shape */}
        <div className="d-flex position-absolute top-100 start-0 w-100 overflow-hidden mt-n4 mt-sm-n1" style={{ "color": "var(--bs-gray-900)"}} >
        <div className="position-relative start-50 translate-middle-x flex-shrink-0" style={{ "width": "3788px"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="3788" height="144" viewBox="0 0 3788 144"><path style={{"color": "#0b0f19"}} fill="currentColor" d="M0,0h3788.7c-525,90.2-1181.7,143.9-1894.3,143.9S525,90.2,0,0z"/></svg>
        </div>
        </div>
        <div className="d-none d-lg-block" style={{ "height": "300px"}}></div>
        <div className="d-none d-md-block d-lg-none" style={{ "height": "150px"}}></div>
    </div>
    <div className="position-relative zindex-5 mx-auto" style={{ "maxWidth": "1250px"}}>
        <div className="d-none d-lg-block"  style={{ "marginTop": "-300px"}}></div>
        <div className="d-none d-md-block d-lg-none" style={{ "marginTop": "-150px"}} ></div>
        <div className="position-relative rounded-3 overflow-hidden mb-lg-3">
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center zindex-5">
            <video  className="w-100" autoPlay muted loop style={{ "objectFit": "-150px"}} poster="assets/img/landingcogmatic/VideoImg.png">
            <source src="assets/img/landingcogmatic/CogmaticVideo.mp4" type="video/mp4" />
            </video>
        </div>
        <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-35"></span>
        <img src="assets/img/landingcogmatic/VideoImg.png" alt="Cover" />
        </div>
        <span className="position-absolute rounded-3  top-0 start-0 w-100 h-100 bg-dark opacity-35"></span>
    </div>
    </section>

    {/* Our Expertise */}
    <section className="container py-5  ">
    <h2 className="h1 text-center pt-1 pt-xl-1 mb-lg-4 ">Our Expertise</h2>
    <p className="text-center">We use state-of-the-art Machine Learning and Data Science to develop impactful solutions.</p>
    
    <Swiper className="swiper mx-n2" 
        slidesPerView = {1}
        spaceBetween = {8}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        pagination= {{ clickable: true }}
        breakpoints={{
            500: {
           
            slidesPerView: 2,
            },

            800: {
            slidesPerView: 3,
            },

            1200:{
            slidesPerView: 4,
            },
        }}
    >
    <div className="swiper-wrapper">

        {/* Item */}
        <SwiperSlide className="swiper-slide h-auto py-3">
            <div className=" h-100 card-body card-hover  mx-2">
            <span className="iconify display-5 fw-normal card-icon text-muted" data-icon="radix-icons:file-text"></span>
            <h3 className="h5 pt-3 pb-1 mb-2">NLP (Text Processing)</h3>
            <p className="mb-0">Extract meaningful information from unstructured text. Rank and retrieve information and deploy conversational agents.</p>
            </div>
        </SwiperSlide>

        {/* Item */}
        <SwiperSlide className="swiper-slide h-auto py-3">
            <div className=" h-100 card-body card-hover  mx-3">
            <span className="iconify display-5 fw-normal card-icon text-muted" data-icon="clarity:eye-line"></span>
            <h3 className="h5 pt-3 pb-1 mb-2">Computer Vision</h3>
            <p className="mb-0">Perceive information from images. Identify, detect, and track objects in an image or video.</p>
            </div>
        </SwiperSlide>

        {/* Item */}
        <SwiperSlide className="swiper-slide h-auto py-3">
            <div className=" h-100 card-body card-hover  mx-2">
            <span className="iconify display-5 fw-normal card-icon text-muted" data-icon="carbon:chart-cluster-bar"></span>
            <h3 className="h5 pt-3 pb-1 mb-2">Predictive Analytics</h3>
            <p className="mb-0">Predict future changes using historical data. Anticipate customer behavior and streamline business operations.</p>
            </div>
        </SwiperSlide>

        {/* Item */}
        <SwiperSlide className="swiper-slide h-auto py-3">
            <div className=" h-100 card-body card-hover mx-2">
            <span className="iconify display-5 fw-normal card-icon text-muted" data-icon="bi:laptop"></span>
            <h3 className="h5 pt-3 pb-1 mb-2">Full-Stack Software Development</h3>
            <p className="mb-0">Develop scalable, intuitive software incorporating leading principles of design and enterprise-level security.</p>
            </div>
        </SwiperSlide>
    </div>

        {/* Pagination (bullets) */}
        <div className="swiper-pagination position-relative d-xl-none pt-3 mt-4"></div>
    </Swiper>

    <div className="d-flex flex-column flex-sm-row justify-content-center pt-3 pt-sm-4 mb-lg-3">
        <Link to = "/expertise" className="btn btn-primary ">Learn more</Link>
    </div>
    </section>

    {/* What We Do */}
    <section className="bg-secondary pb-md-2 pb-lg-5 mt-lg-5 ">
    <div className="d-none d-lg-block" style={{"marginTop": "-60px"}}></div>
    <div className="container pb-lg-4 pt-5 pb-sm-0 mt-lg-5">
        <h2 className="h1 text-center text-md-start mb-lg-5 pt-1 pt-md-4">What We Do</h2>
        <div className="row row-cols-1 row-cols-md-2">

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/services/cms.svg" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Bespoke Machine Learning Solitions
                </h2>
                <p className="fs-sm text-body mb-0">
                We are experts in developing bespoke machine learning solutions. Our team has extensive experience developing, deploying, and maintaining machine learning solutions at scale within industries as diverse as insurance to oil and gas. Our experience and iterative approach allows us to deliver truly state-of-the-art solutions in the shortest time.</p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/services/development-consulting.svg" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Product Development and Consulting
                </h2>
                <p className="fs-sm text-body mb-0">
                It doesn’t matter if you are a startup or an established enterprise. We can bring your software initiatives to life. We understand what it takes to deploy machine learning solutions at scale. Our team of experienced designers, full-stack developers and machine learning engineers can see your products from conception through deployment, allowing you to develop the most effective solutions fast.
                </p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/services/enterprise=development.svg" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Enterprise Software Development
                </h2>
                <p className="fs-sm text-body mb-0">
                We are experts in enterprise application development. We have experience designing and developing world-className banking, HCM, and SaaS enterprise applications. No project is too large for our team. We incorporate enterprise level design and security into everything we develop. When partnering with Cogmatic, you can rest assured that your custom enterprise applications are in the very best of hands.
                </p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/services/iot-edge.svg" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                IoT Edge Implementations
                </h2>
                <p className="fs-sm text-body mb-0">
                Edge computing moves computations physically closer to where they are needed. The low-latency, increased privacy, and improved reliability that edge computing provides, is enabling transformative solutions that will affect all industries. We are experts in designing and deploying edge and edge AI solutions. Contact us today to discuss how edge implementations can benefit your business.
                </p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/services/business-process-automation.svg" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Business Process Automation
                </h2>
                <p className="fs-sm text-body mb-0">
                There has never been a better time to discover how to streamline your business processes, reduce expenses, and increase operational efficiency. Machine learning powered business process automation is revolutionising how business operates.
                </p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/services/web-development.svg" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Web Application Development
                </h2>
                <p className="fs-sm text-body mb-0">
                Whether you are developing your own SaaS product, streamlining a business process, or seeking to engage your customers, web applications can bring your ideas to life at scale. We work diligently to ensure our software meets your every requirement, and we incorporate leading principles of design and enterprise-level security into everything we build.</p>
            </div>
            </div>
        </div>
        </div>
    </div>
    </section>

    {/* Case studies */}
    <section className="bg-secondary pt-1">
        <div className="d-none d-lg-block" style={{"marginTop": "-60px"}}></div>
        <div className="position-relative py-lg-4 py-xl-5">

            {/* Swiper tabs */}
            <div className="swiper-tabs position-absolute top-0 start-0 w-100 h-100">
                <div id="image-1" className="jarallax position-absolute top-0 start-0 w-100 h-100 swiper-tab active" data-jarallax data-speed="0.4">
                <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-35"></span>
                <div className="jarallax-img" style={{"backgroundImage": " url(assets/img/landingcogmatic/OrbixBg.jpg)"}} ></div>
                </div>
                <div id="image-2" className="jarallax position-absolute top-0 start-0 w-100 h-100 swiper-tab" data-jarallax data-speed="0.4">
                    <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-35"></span>
                    <div className="jarallax-img" style={{"backgroundImage": "url(assets/img/landingcogmatic/OrbixBg.jpg)"}}></div>
                </div>
            </div>

            {/* Swiper slider */}
            <div className="container position-relative zindex-5 py-5">
                <div className="row py-2 py-md-3">
                    <div className="col-xl-5 col-lg-7 col-md-9">
                        <h2 className="h1 text-center text-md-start mb-lg-5" style={{"color": "white"}}>Case Studies</h2>

                        {/* Slider controls (Prev / next) */}
                        <div className="d-flex justify-content-center justify-content-md-start pb-3 mb-3">
                        <button type="button" id="casestudy-prev" className="btn btn-prev btn-icon btn-sm bg-white me-2">
                            <i className="bx bx-chevron-left"></i>
                        </button>
                        <button type="button" id="casestudy-next" className="btn btn-next btn-icon btn-sm bg-white ms-2">
                            <i className="bx bx-chevron-right"></i>
                        </button>
                        </div> 

                        {/* Card */}
                        <div className="card bg-white shadow-sm p-3">
                            <div className="card-body">
                                <Swiper
                                spaceBetween= {30}
                                loop = {true}
                                tabs = {true}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                pagination= {{ clickable: true }}
                                navigation = {{
                                    prevEl : "#casestudy-prev",
                                    nextEl : "#casestudy-next",
                                
                                }} 
                                >
                                <div className="swiper-wrapper">

                                    {/* Item */}
                                    <SwiperSlide className="swiper-slide" data-swiper-tab="#image-1">
                                    <img src="assets/img/logo/logo_black_colour.png" className="d-block mb-3" width="35%" alt="Logo" style={{"color":"black"}} />
                                    <p className="fs-sm text-uppercase border-bottom pb-3 mb-3" style={{"color": "#6366F1"}}>The Future of NDT</p>
                                    <p className="pb-2 pb-lg-3 mb-3">The AI powered cloud PACS software transforming industrial radiographic testing.</p>
                                    <Link to="/orbix" className="btn btn-primary ">View case study</Link>
                                    </SwiperSlide>

                                    {/* Item */}
                                    <SwiperSlide className="swiper-slide" data-swiper-tab="#image-2">
                                    <img src="assets/img/logo/mindforgelogo.svg" className="d-block mb-3" width="35%" alt="Logo" style={{"color":"black"}} />
                                    <p className="fs-sm text-uppercase border-bottom pb-3 mb-3" style={{"color": "#6366F1"}}>Forge your AI future at lightning</p>
                                    <p className="pb-2 pb-lg-3 mb-3">MindForge is the engine that drives your custom AI solutions. With the ability to quickly build and deploy the latest functionality.</p>
                                    <Link to="/mindforge" className="btn btn-primary ">View case study</Link> 
                                    </SwiperSlide>
                                </div>
                                {/* Pagination (bullets) */}
                                <div className="swiper-pagination position-relative mt-5"></div>
                            </Swiper>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </section>

  


   

    {/* Rapid Model DEvelopment */}
    <section className=" bg-secondary pb-md-5 pb-lg-5">
    <div className="container pt-5 pb-4">
        <h2 className="h1 text-center pt-1 pt-xl-3 mb-lg-4">Rapid Model Development</h2>
        <p className="text-center">Make your project a reality and maximize the impact of your company's AI initiatives.</p>

        <Swiper className="swiper mx-n2" 
        slidesPerView = {1}
        spaceBetween = {8}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        pagination= {{ clickable: true }}
        breakpoints={{
            500: {
            slidesPerView: 2,
            },

            800: {
            slidesPerView: 3,
            },

            1200:{
            slidesPerView: 4,
            },
        }}
        >
        <div className="swiper-wrapper">

            {/* Item */}
            <SwiperSlide className="swiper-slide h-auto py-5 ">
            <span className="purple-circle">1</span>
            <div className=" h-100 card card-body bg-info card-hover border-0  mx-2">
                <h3 className="h5 pt-3 pb-1 mb-2" style={{"paddingRight": "5%"}}>Opportunity Assessment</h3>
                <p className="mb-0">We begin by assessing your project goals, finding the opportunities that will generate the greatest ROI and, in some cases, help you create a comprehensive data strategy.</p>
            </div>
            </SwiperSlide>

            {/* Item */}
            <SwiperSlide className="swiper-slide h-auto py-5 ">
            <span className="purple-circle">2</span>
            <div className=" h-100 card card-body card-hover bg-info border-0 mx-2">
                <h3 className="h5 pt-3 pb-1 mb-2">Data Preparation</h3>
                <p className="mb-0">Effective machine learning runs on good data. We will refine your data, harmonize data from disparate sources, and discover ways we can use your expertise to increase the quality of your data.</p>
            </div>
            </SwiperSlide>

            {/* Item */}
            <SwiperSlide className="swiper-slide h-auto py-5 ">
            <span className="purple-circle">3</span>
            <div className=" h-100 card card-body card-hover bg-info border-0 mx-2">
                <h3 className="h5 pt-3 pb-1 mb-2">Model Development</h3>
                <p className="mb-0">We will rapidly prototype state-of-the-art machine learning models and conduct numerous experiments simultaneously, to quickly discover the perfect solution to your unique business problem.</p>
            </div>
            </SwiperSlide>

            {/* Item */}
            <SwiperSlide className="swiper-slide h-auto py-5 ">
            <span className="purple--circle display-block">4</span>
            <div className=" h-100 card card-body card-hover bg-info border-0 mx-2">
                <h3 className="h5 pt-3 pb-1 mb-2">Full-Stack Software Development</h3>
                <p className="mb-0">We develop scalable, intuitive software for your AI application incorporating leading principles of design and enterprise-level security.</p>
            </div>
            </SwiperSlide>
        </div>

        {/* Pagination (bullets) */}
        <div className="swiper-pagination position-relative d-xl-none pt-5 mt-5"></div>
        </Swiper>
    </div>
    </section>

    {/* Testimonials */}
    <Testimonial />

    {/* Brands (Carousel) */}
    <Brands />

    {/* Sign up CTA */}
    <SignUp />

    {/* Footer */}
    <Footer />
    </main>

    {/* Back to top button */}
    
  
    </>
  )
}

export default Home