import React from 'react';
import './mindforge.css'
import Testimonial from '../../../components/testimonial/testimonial.component';
import Brands from '../../../components/brands/brands.componenet'
import SignUp from '../../../components/signup/signup.component';
import Footer from '../../../components/footer/footer.componenet';

function MindForge() {
  
  return (
    <>
    {/* Page wrapper for sticky footer */}
    <main className="page-wrapper">

        {/* Hero */}
        <section class="position-relative d-flex align-items-center min-vh-100 py-5 bg-dark-custom overflow-hidden">
        <span class="position-absolute top-0 start-0 d-dark-mode-block d-none w-100 h-100 bg-secondary opacity-75"></span>
        <div class="container position-relative dark-mode mt-5 mb-lg-5 mb-md-4 mb-3 pt-3 pb-xl-3">
          <div class="row gy-5 align-items-center">
            <div class="col-xl-5 col-lg-6">
           
              <div class="position-relative zindex-5 text-lg-start text-center">
              <img src='./assets/img/logo/mindforgelogo.svg' className='mb-4' alt='logo'/>
                <h1 class="display-5 mb-4 pb-lg-3 pb-md-2">Forge your AI future at lightning speed</h1>
               {/*  <p class="mb-4 pb-lg-3 pb-md-2">
                  <span class="me-2 text-light">We are the Top Rated agency on</span>
                  <img src="assets/img/landing/software-agency-3/clutch-rating.png" width="193" alt="Clutch rating" class="mt-n1"/>
                </p> */}
                <a href="#/" className="btn btn-md btn-primary">Start your project</a>
              </div>
            </div>
            <div className="col-lg-6 offset-xl-1">
              <div className="position-relative ms-xl-0 ms-lg-4">
                <div className="position-absolute top-50 start-50 translate-middle ratio ratio-1x1" style={{"width": "125%" ,  "max-width": "49.75rem"}}>
                  <div className="p-md-0 p-5">
                    <img src="assets/img/landingcogmatic/hero-circles.png" alt="Shape" className="hero-animation-spin p-md-0 p-5"/>
                  </div>
                </div>
                <div className="position-relative row row-cols-sm-2 row-cols-1 gx-xl-4 gx-lg-3 gx-md-4 gx-3">
                  <div className="col">
                    <div className="d-sm-grid d-flex gap-xl-4 gap-lg-3 gap-md-4 gap-sm-3 gap-2">
                      <div className="d-flex align-items-center justify-content-center bg-secondary rounded-3" style={{"min-height" : "176px" ," backdrop-filter": "blur(6px)"}}>
                        <div className="p-xl-4 p-sm-3 p-2 fs-md fw-semibold text-center">
                          <span className="hero-animation-fade text-white">Revolutionize text creation</span>
                        
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center bg-secondary rounded-3" style={{"min-height":" 176px"," backdrop-filter": "blur(6px)"}}>
                        <div className="p-xl-4 p-sm-3 p-2 fs-md fw-semibold text-center">
                          <span className="hero-animation-fade hero-animation-delay-2 text-white">Improve productivity with AI</span>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-sm-5 mt-2 pt-sm-5">
                    <div className="d-sm-grid d-flex gap-xl-4 gap-lg-3 gap-md-4 gap-sm-3 gap-2">
                      <div className="d-flex align-items-center justify-content-center bg-secondary rounded-3" style={{"min-height": "176px", "backdrop-filter": "blur(6px)"}}>
                        <div className="p-xl-4 p-sm-3 p-2 fs-md fw-semibold text-center">
                          <span className="hero-animation-fade hero-animation-delay-1 text-white">Discover hidden insights</span>
                        
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center bg-secondary rounded-3" style={{"min-height": "176px", "backdrop-filter": "blur(6px)"}}>
                        <div className="p-xl-4 p-sm-3 p-2 fs-md fw-semibold text-center">
                          <span className="hero-animation-fade hero-animation-delay-3 text-white">Extract complex information fast</span>
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        
      {/*Feature section */}
      <section className="container mt-5 pt-5 mb-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row align-items-center mb-xl-5 mb-4">

        { /* Text */}
          <div className="col-md-6 col-xl-5 text-center text-md-start mb-5 mb-md-0">
            <h2 className="h1 pb-2 pb-lg-3">The future of AI is in your hands with MindForge</h2>
            <p className="pb-2 mb-4 mb-lg-5">MindForge is the engine that drives your custom AI solutions. With the ability to quickly build and deploy the latest functionality, you'll have a competitive edge in no time. Let us help you harness the power of large language models and transform the game. Get ahead with MindForge.</p>
           
           {/* <hr/>  <div className="d-flex justify-content-center justify-content-md-between pt-4 pt-lg-5">
              <div className="mx-3 mx-md-0">
                <div className="display-3 text-dark mb-1">23%</div>
                <span>Nulla venenatis everys</span>
              </div>
              <div className="mx-3 mx-md-0">
                <div className="display-3 text-dark mb-1">132b</div>
                <span>Dictum in marko elementum</span>
              </div>
            </div> */}
          </div>

         { /* Parallax gfx */}
          <div className="col-md-6 offset-xl-1">
            {/* <div className="parallax ratio ratio-1x1 mx-auto" style={{"max-width": "550px"}}>
              <div className="parallax-layer position-absolute zindex-2" data-depth="-0.15">
                <img src="assets/img/landing/saas-4/avatars.png" alt="Avatar"/>
              </div>
              <div className="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100" data-depth="0.1">
                <div className="rounded-circle bg-primary" style={{"width": "70%", "height":" 70%", "opacity": ".06"}}></div>
              </div>
              <div className="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100" data-depth="0.2">
                <div className="rounded-circle bg-primary" style={{"width": "55%"," height": "55%", "opacity": ".06"}}></div>
              </div>
              <div className="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100 zindex-3" data-depth="0.4">
                <div className="d-flex justify-content-center align-items-center rounded-circle bg-primary" style={{"width": "93px" ," height": "93px", "box-shadow": "0 .1875rem 1.875rem -.125rem rgba(99,102,241, .95)"}}>
                  <span className="h5 text-light mb-0">400K+</span>
                </div>
              </div>
              <div className="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100" data-depth="0.3">
                <div className="rounded-circle bg-primary" style={{"width": "40%", "height":" 40%"," opacity": ".06"}}></div>
              </div>
              
            </div> */}
            <img src='assets/img/landingcogmatic/featuresicons.png' alt='iconsimg' />
          </div>
        </div>
      </section>

      {/* Features */}
    <section className="bg-secondary-custom pb-md-2 pb-lg-5 mt-lg-5 ">
    <div className="d-none d-lg-block" style={{"marginTop": "-60px"}}></div>
    <div className="container pb-lg-4 pt-5 pb-sm-0 mt-lg-5">
        <h2 className="h1 text-center text-md-start mb-lg-4 pt-1 pt-md-4">Redefine what's possible</h2>
        <div className="row align-items-center pb-5 mb-lg-2">
            <div className="col-md-8 text-center text-md-start">
              <p className="fs-lg text-muted-custom mb-md-0">Experience the future of AI, Now.</p>
            </div>  
        </div>
        <div className="row row-cols-1 row-cols-md-2">

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary-custom  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/texticons/text-generation.png" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Text Generation
                </h2>
                <p className="fs-sm text-body mb-0">
                Enable the power to automate content creation, personalize customer interactions, and generate new ideas with advanced text generation technology. Create product descriptions, customer emails, and marketing materials with ease.</p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary-custom  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/texticons/semantic-search.png" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Semantic Search
                </h2>
                <p className="fs-sm text-body mb-0">
                Unlock the power of advanced search technology that understands the true meaning behind user queries to deliver more relevant results. Transform your business processes, discover hidden insights in your company data, and understand the meaning behind user queries to deliver more relevant results
                </p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary-custom  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/texticons/summarization.png" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Summarization
                </h2>
                <p className="fs-sm text-body mb-0">
                Extract key information and insights from lengthy documents with advanced text summarization technology. Save time and increase productivity with concise summaries of company data, documents, and more.
                </p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary-custom  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/texticons/information-extraction.png" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Information Extraction
                </h2>
                <p className="fs-sm text-body mb-0">
                Quickly and accurately identify and extract complex information from unstructured data, such as contracts, emails, and customer feedback. Transform unstructured data into structured data. Automatically extract valuable insights from documents, emails, and web pages. Streamline your workflow, identify trends, and make informed decisions. 
                </p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary-custom  rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/texticons/question-and-answer.png" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Question and Answering
                </h2>
                <p className="fs-sm text-body mb-0">
                Instantly find the answers you need from structured or unstructured data, no matter the language. Streamline your workflow and reduce time spent searching through documents, enabling faster decision-making and increased productivity. Get the answers you need, when you need them.
                </p>
            </div>
            </div>
        </div>

        {/* Item */}
        <div className="col py-4 my-2 my-sm-3">
            <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
                <div className="d-inline-block bg-primary-custom rounded-3 position-absolute top-0 translate-middle-y p-3">
                <img src="assets/img/texticons/text-classification.png" className="d-block m-1" width="40" alt="Icon" />
                </div>
                <h2 className="h4 d-inline-flex align-items-center">
                Text Classification
                </h2>
                <p className="fs-sm text-body mb-0">
                Instantly categorize text into predefined categories with high accuracy, whether through training a model to recognize specific categories (fine-tuning) or without prior training (zero-shot). Increase efficiency in content organization, customer service, and more with this powerful AI technology.</p>
            </div>
            </div>
        </div>
        </div>
    </div>
    </section>
     

      {/* Testimonials */}
      <Testimonial />

      {/* Brands (Carousel) */}
      <Brands />

      {/* Sign up CTA */}
      <SignUp />

      {/* Footer */}
      <Footer />
    </main> 
    </>
  )
}

export default MindForge;