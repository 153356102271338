import React, { useRef } from 'react';
import Testimonial from '../../components/testimonial/testimonial.component'
import Brands from '../../components/brands/brands.componenet'
import Footer from '../../components/footer/footer.componenet';
import emailjs from  '@emailjs/browser'

function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    alert('message sent')

    emailjs.sendForm(
      'service_s0onm5z', 
      'template_9r14her', 
      form.current, 
      'iGmY1xiW2zZ-4bmG3')
      .then((result) => {
          console.log(result.text);
       
      }, (error) => {
          console.log(error.text);
      });
  };
  return (

    <>
    {/* Page wrapper for sticky footer */}
    <main className="page-wrapper">

{/* Hero */}
<section className="jarallax position-relative d-flex align-items-center min-vh-50 bg-dark " data-jarallax data-img-position="10%" data-speed="0.5">
  <div className="container position-relative zindex-5 py-5">
  {/* Text */}
    <div className="container zindex-5 pt-5" >
      <div className="row justify-content-center text-center pt-4 pb-sm-2 py-lg-5">
        <div className="col-xl-9 col-lg-9 col-md-10  ">
          <h1 className="display-6 text-light pt-sm-2 pb-1 ">Contact Us</h1>
          <p className="fs-lg text-light opacity-70 pb-2 pb-sm-0 text-center">
          Contact us to dicuss a project or partnership today.<br/>We are only one message away
        </p>
        </div>
      </div>
    </div>
  </div>
</section>

{/*Contact Us  */}
<section className=" container mt-5 pt-5  ">
{/*Contact Us Text */}
<div className="bg-secondary rounded-3 py-5 px-3 px-sm-0 mb-xl-4">
  <form className="needs-validation row justify-content-center py-lg-3 py-xl-4" novalidate ref={form} onSubmit={sendEmail}>
    <div className="col-xl-8 col-lg-9 col-md-10 col-sm-11">
      <h2 className="h1 pb-lg-1 mb-4">Get in Touch</h2>
      <p className="fs-lg opacity-70 pb-lg-1 mb-4"  style={{"colort": "#2D304A"}}>Discover how machine learning-powered AI can benefit your business.<br/>
      Estimate the budget for your project. Learn more about our expertise and experience.</p>
      <div className="row">
        <div className="col-sm-6 mb-4">
          <label for="name" className="form-label fs-base">Full name</label>
          <input type="text" id="name" name='user_name' className="form-control form-control-lg" required />
          <div className="invalid-feedback">Please enter your name!</div>
        </div>
        <div className="col-sm-6 mb-4">
          <label for="email" className="form-label fs-base">Email address</label>
          <input type="email" id="email" name='user_email' className="form-control form-control-lg" required/>
          <div className="invalid-feedback">Please provide a valid email address!</div>
        </div>
        <div className="col-12 mb-4">
          <label for="message" className="form-label fs-base">Message</label>
          <textarea id="message" name='message' className="form-control form-control-lg" rows="4" required></textarea>
          <div className="invalid-feedback">Please write your message!</div>
        </div>
      </div>
      <div className="form-check mb-4">
        <input type="checkbox" id="terms" className="form-check-input" required/>
        <label for="terms" className="form-check-label fs-base">I agree to the <a href="{#}">Terms &amp; Conditions</a></label>
      </div>
      <button type="submit" className="btn btn-primary  shadow-primary">Send message</button>
    </div>
  </form>
</div>

{/*Page Title */}
<h1 className="pt-5 mt-lg-5 pb-3 pb-lg-4">Contact Us</h1>
<div className="row pb-5 mb-md-2 mb-lg-4 mb-xl-5">

{/*Page Title */}
  <div className="col-xl-5 col-md-6 mb-4 mb-md-0">
    <div className="card card-hover mb-3">
      <div className="card-body d-flex align-items-start">
        <i className="bx bx-envelope fs-3 bg-secondary text-primary d-inline-block rounded-circle p-3"></i>
        <div className="ps-4">
          <h3 className="h4 pb-1 mb-2">Email us</h3>
          <p className="pb-1 mb-2 ">Please feel free to send us a message. We will respond as soon as possible.</p>
          <a href="mailto:hello@cogmatic-tech.com" className="btn btn-link stretched-link fs-base px-0">
            hello@cogmatic-tech.com
            <i className="bx bx-right-arrow-alt fs-4 ms-1"></i>
          </a>
        </div>
      </div>
    </div>
    <div className="card card-hover mb-3">
      <div className="card-body d-flex align-items-start">
        <i className="bx bx-phone-call fs-3 bg-secondary text-primary d-inline-block rounded-circle p-3"></i>
        <div className="ps-4">
          <h3 className="h4 pb-1 mb-2">Call any time</h3>
          <p className="pb-1 mb-2">If you need immediate assistance feel free to call us any time.</p>
          <a href="tel:+442045266405" className="btn btn-link stretched-link fs-base px-0">+44 7935 241 655 - UK</a>
          <br/>
          <a href="tel:1-332-373-2745" className="btn btn-link stretched-link fs-base px-0 ">1-646-583-1246 - US</a>
        </div>
      </div>
    </div>
    <div className="card card-hover">
      <div className="card-body d-flex align-items-start">
        <i className="bx bx-map fs-3 bg-secondary text-primary d-inline-block rounded-circle p-3"></i>
        <div className="ps-4">
          <h3 className="h4 pb-1 mb-2">Visit us</h3>
          <p className="pb-1 mb-2">71-75 Shelton Street, Covent Garden,<br/> London United Kingdom, WC2H 9JQ</p>
          <a href= "https://www.google.com/maps/place/Cogmatic/@51.514904,-0.123584,17z/data=!4m6!3m5!1s0x487605a0770081cd:0x7d498c0d5c3392fa!8m2!3d51.5149037!4d-0.1235842!16s%2Fg%2F11n5qb5r_4?hl=en" className="btn btn-link stretched-link fs-base px-0">
            Get directions
            <i className="bx bx-right-arrow-alt fs-4 ms-1"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

 {/*Map */}
  <div className="col-md-6 offset-xl-1">
    <div className="d-flex flex-column h-100 shadow-sm rounded-3 overflow-hidden">
      <iframe title='map' className="d-block h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9080078613915!2d-0.12577288426257785!3d51.514903679636326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605a0770081cd%3A0x7d498c0d5c3392fa!2sCogmatic!5e0!3m2!1sen!2str!4v1659592471694!5m2!1sen!2str" style={{"border": "0" , "minHeight" : "300px"}} allowfullscreen="" loading="lazy"></iframe>
    </div>
  </div>
</div>
</section>






{/* Testimonials */}
<Testimonial />

{/* Brands (Carousel) */}
<Brands />

<div className='contact-brand-space '></div>

{/* Footer */}
<Footer />
</main>
    </>
   
  )
}

export default Contact