import React from 'react'
import Testimonial from '../../components/testimonial/testimonial.component'
import Brands from '../../components/brands/brands.componenet'
import SignUp from '../../components/signup/signup.component';
import Footer from '../../components/footer/footer.componenet';

function About() {
  return (
    <>
              {/* Page wrapper for sticky footer */}
    <main className="page-wrapper">

      {/* Hero */}
      <section className="jarallax position-relative d-flex align-items-center min-vh-50 bg-dark " data-jarallax data-img-position="10%" data-speed="0.5">
        <div className="container position-relative zindex-5 py-5">
        {/* Text */}
          <div className="container zindex-5 pt-5" >
            <div className="row justify-content-center text-center pt-4 pb-sm-2 py-lg-5">
              <div className="col-xl-9 col-lg-9 col-md-10  ">
                <h1 className="display-6 text-light pt-sm-2 pb-1 ">About Us</h1>
                <p className="fs-lg text-light opacity-70 pb-2 pb-sm-0 mb-4 mb-sm-5 text-center">
                  <b>Cogmatic</b> is a full-cycle software development and machine learning consultancy.
              </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*About Us Text */}
      <section className=" bg-light ">
        <div className="container">
          <div className="row pb-2 mb-md-4 mb-lg-5 pt-5 d-flex justify-content-center">

            <div className="col-md-10 col-sm-12  pt-md-4 pt-lg-5">
              <div className="pe-md-4 me-md-2">
                <h2 className="h1 pt-1 pt-xl-1 mb-lg-4 ">About Us</h2>
                <p className=" d-lg-block expertise-in-p fs-6">
                  Cogmatic is a full-cycle machine learning and software development consultancy based in London. We develop custom AI Solutions and Enterprise Software.
                </p>
                <p className=" d-lg-block expertise-in-p fs-6">
                 We are a highly experienced team of machine learning and software engineers. We have experience developing and deploying enterprise software solutions at scale within a wide range of industries, such as, banking, insurance, and oil and gas. Our experience and iterative approach allows us to deliver state-of-the-art solutions to you in the shortest amount of time.
                </p>
                <p className=" d-lg-block expertise-in-p fs-6">
                Our team of Machine Learning Engineers are experts at implementing AI at scale. We know what works, and more importantly, we know what’s hype. We take the time to truly understand your unique business problem and provide an honest assessment. We examine the viability of current machine learning strategies and their potential impact for your business.
                </p>
                <p className=" d-lg-block expertise-in-p fs-6">
                Whether you have a specific project in mind or are just curious about how machine learning powered-AI can benefit your business, we will always advise you to pursue the best solution for your company, even if that does not involve AI. You can always count on our engineers to help you reach your goals.
                </p>

                {/* <hr className="mt-5 mb-4"/> */}

                {/* <h2 className="h1 pt-1 pt-xl-1 mb-lg-4 ">Our Mission</h2>
                <p className=" d-lg-block expertise-in-p fs-6">
                  We are dedicated to our client's long-term success. Our mission is to empower businesses with impactful machine learning-powered AI technology. We believe in building amazing software for our clients, solving problems in the best way, and using machine learning where it will have the greatest impact.
                </p>

                <ul className="list-unstyled pb-4 mb-0 expertise-in-p ">
                  <li className="d-flex align-items-center pb-1 mb-2">
                    <i className="bx bx-check-circle fs-xl text-primary me-2"></i>
                     Deliver the highest quality software solutions for our clients
                  </li>
                  <li className="d-flex align-items-center pb-1 mb-2">
                    <i className="bx bx-check-circle fs-xl text-primary me-2"></i>
                     Solve problems that will have the greatest impact
                  </li>
                  <li className="d-flex align-items-center pb-1 mb-2">
                    <i className="bx bx-check-circle fs-xl text-primary me-2"></i>
                     Support longterm success of our clients by providing ongoing support and advice
                  </li>
               </ul> */}

              </div>
            </div>
          </div>
        </div>
      </section>






      {/* Testimonials */}
      <Testimonial />

      {/* Brands (Carousel) */}
      <Brands />

      {/* Sign up CTA */}
      <SignUp />

      {/* Footer */}
      <Footer />
    </main>

    </>
  )
}

export default About