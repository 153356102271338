import React from 'react'
import Testimonial from '../../../components/testimonial/testimonial.component';
import Brands from '../../../components/brands/brands.componenet'
import SignUp from '../../../components/signup/signup.component';
import Footer from '../../../components/footer/footer.componenet';

function ExpertiseInner6() {
  
  return (
    <>
    {/* Page wrapper for sticky footer */}
    <main className="page-wrapper">

        {/* Hero */}
        <section className="jarallax position-relative d-flex align-items-center min-vh-50 bg-dark">
          <div className="ex-inner-bg-img w-100"  style={{"backgroundImage": "url(../assets/img/expertise/cog-img-1.1.png"}}>     
            <div className="container position-relative zindex-5 py-5"> 
            {/* Text */}
              <div className="container zindex-5 pt-5" >
                <div className="row justify-content-center text-center pt-4 pb-sm-2 py-lg-5">
                  <div className="col-xl-9 col-lg-9 col-md-10 py-5 ">
                    <h1 className="display-6 text-light pt-sm-2 pb-1 ">Predictive Analytics</h1> 
                    <p className="fs-lg text-light opacity-70 pb-2 pb-sm-0 mb-4 mb-sm-5 text-center">
                    Streamline and optimize the decision making process across workflows by accurately predicting the most likely future events before they happen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/*Expertise list */}
        <section className=" bg-secondary pb-5 pb-lg-5 pt-5 pt-lg-5">

          {/* Item */}
          <div className="container">
          <div className="row pb-5 mb-md-4 mb-lg-5 pt-5 d-flex align-items-center mg-container-1">
              <div className="col-md-5 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center">           
              <img src="../assets/img/expertise/cog-img-1.png" className="" width="400" alt="Graphic"/>           
              </div>
              <div className="col-md-7 order-md-1 pt-md-4 pt-lg-5">
              <div className="pe-md-4 me-md-2">
                  <h2 className="h4">Predictive Analytics</h2>              
                  <p className=" d-lg-block expertise-in-p fs-6">
                  Predictive analytics refers to a family of machine learning powered-AI models and methods that utilize complex patterns in data to make accurate predictions of future events. Predictive analytics allows companies to better understand their customers, optimize their business operations, and forecast changes in real-time.
                  </p> 
              </div>
              </div>
          </div>
          </div>
      </section>

      {/* Testimonials */}
      <Testimonial />

      {/* Brands (Carousel) */}
      <Brands />

      {/* Sign up CTA */}
      <SignUp />

      {/* Footer */}
      <Footer />
    </main> 
    </>
  )
}

export default ExpertiseInner6