import React from 'react';
import { Outlet , Link } from 'react-router-dom';
import Testimonial from '../../components/testimonial/testimonial.component';
import Brands from '../../components/brands/brands.componenet'
import SignUp from '../../components/signup/signup.component';
import Footer from '../../components/footer/footer.componenet';


function Expertise() {
  
  return (
    <>
     <Outlet/>
    {/* Page wrapper for sticky footer */}
    <main className="page-wrapper">
    

      {/* Hero */}
  
      <section className="jarallax position-relative d-flex align-items-center min-vh-50 bg-dark" data-jarallax data-img-position="50%" data-speed="0.5">
      <div className="ex-bg-img w-100"  style={{"backgroundImage": "url(assets/img/expertise/ExpertiseBg.png"}} >
      <div className="container position-relative zindex-5 py-5">
        {/* Text */}
          <div className="container zindex-5 pt-5" >
            <div className="row justify-content-center text-center pt-4 pb-sm-2 py-lg-5">
              <div className="col-xl-9 col-lg-9 col-md-10 py-5 ">
                <h1 className="display-6 text-light pt-sm-2 pb-1 ">Our Expertise</h1>
                <p className="fs-lg text-light opacity-70 pb-2 pb-sm-0 mb-4 mb-sm-5 text-center">
                Our team of developers and machine learning experts have extensive experience developing software 
                  and carrying out applied machine learning initiatives at scale.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        
      </section>  

      {/*Expertise list */}
      <section className=" bg-secondary pb-5 pb-lg-5 pt-5 pt-lg-5">

        {/*Item */}
        <div className="container">
          <div className="row pb-5 mb-md-4 mb-lg-5 pt-5 d-flex align-items-center mg-container-1">
            <div className="col-md-5 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center"> 
              <img src="assets/img/expertise/cog-img-1.png" className="" width="400" alt="Graphic"/>           
            </div>
            <div className="col-md-7 order-md-1 pt-md-4 pt-lg-5">
              <div className="pe-md-4 me-md-2">
                <h2 className="h4">Full-Stack Development</h2>              
                <p className=" d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">Design and deploy custom end-to-end software utilizing leading principles of design and enterprise level security.</p>
                {/* <button onClick={() => navigate ('full-stack-development')} className="btn btn-outline-primary">Learn more</button> */}
                <Link to='./full-stack-development' id='fullStackDevelopment' className="btn btn-outline-primary">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pb-lg-5 pt-5 pt-lg-5">

         {/*Item */}
          <div className="container">
            <div className="row pb-5 mb-md-4 mb-lg-5 pt-5 d-flex align-items-center mg-container-2">
              <div className="col-md-5 pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center">            
                  <img src="assets/img/expertise/cog-img-2.png" className="" width="400" alt="graphic"/>           
              </div>
              <div className="col-md-7 order-md-1 pt-md-4 pt-lg-5">
                <div className="pe-md-4 me-md-2">
                  <h2 className="h4">Artificial Intelligence</h2>              
                  <p className=" d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">Develop and deploy custom artificial intelligence solutions for your company incorporating state-of-the-art technology.</p>
                  <Link to='/artificial-intelligence' className="btn btn-outline-primary">Learn more</Link>
                 
                </div>
              </div>
            </div>
        </div>
      </section>

      <section className=" bg-secondary pb-5 pb-lg-5 pt-5 pt-lg-5">

         {/*Item */}
        <div className="container">
          <div className="row pb-5 mb-md-4 mb-lg-5 pt-5 d-flex align-items-center mg-container-1">
            <div className="col-md-5 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center">        
                <img src="assets/img/expertise/cog-img-3.png" className="" width="400" alt="Graphic" />           
            </div>
            <div className="col-md-7 order-md-1 pt-md-4 pt-lg-5">
              <div className="pe-md-4 me-md-2">
                <h2 className="h4">Machine Learning</h2>              
                <p className=" d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">Craft scalable machine learning pipelines and embed them into hardware, mobile apps, and web applications.</p>
                <Link to='/machine-learning' className="btn btn-outline-primary">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pb-lg-5 pt-5 pt-lg-5">

         {/*Item */}
          <div className="container">
          <div className="row pb-5 mb-md-4 mb-lg-5 pt-5 d-flex align-items-center mg-container-2">
            <div className="col-md-5 pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center">            
                <img src="assets/img/expertise/cog-img-4.png" className="" width="400" alt="Graphic"/>           
            </div>
            <div className="col-md-7 order-md-1 pt-md-4 pt-lg-5">
              <div className="pe-md-4 me-md-2">
                <h2 className="h4">Natural Language Processing (NLP)</h2>              
                <p className="d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">Extract meaningful information and make    sense of unstructured human language text.</p>
                <Link to='/natural-language-processing' className="btn btn-outline-primary">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-secondary pb-5 pb-lg-5 pt-5 pt-lg-5">

         {/*Item */}
        <div className="container">
          <div className="row pb-5 mb-md-4 mb-lg-5 pt-5 d-flex align-items-center mg-container-1">
            <div className="col-md-5 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center">            
                <img src="assets/img/expertise/cog-img-2.png" className="" width="400" alt="Graphic"/>           
            </div>
            <div className="col-md-7 order-md-1 pt-md-4 pt-lg-5">
              <div className="pe-md-4 me-md-2">
                <h2 className="h4">Computer Vision</h2>              
                <p className=" d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">Software that can extract meaningful information from images. Detect, locate, and track objects in an image or video.</p>
                <Link to='/computer-vision' className="btn btn-outline-primary">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pb-lg-5 pt-5 pt-lg-5">

         {/*Item */}
          <div className="container">
          <div className="row pb-5 mb-md-4 mb-lg-5 pt-5 d-flex align-items-center mg-container-2">
            <div className="col-md-5 pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center">            
                <img src="assets/img/expertise/cog-img-1.png" className="" width="400" alt="Graphic"/>           
            </div>
            <div className="col-md-7 order-md-1 pt-md-4 pt-lg-5">
              <div className="pe-md-4 me-md-2">
                <h2 className="h4">Predictive Analytics</h2>              
                <p className=" d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">Streamline and optimize the decision making process across workflows by accurately predicting the most likely future events before they happen.</p>
                <Link to='/predictive-analytics' className="btn btn-outline-primary">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-secondary pb-5 pb-lg-5 pt-5 pt-lg-5">

         {/*Item */}
        <div className="container">
          <div className="row pb-5 mb-md-4 mb-lg-5 pt-5 d-flex align-items-center mg-container-1">
            <div className="col-md-5 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center">      
              <img src="assets/img/expertise/cog-img-4.png" className="" width="400" alt="Graphic"/>           
            </div>
            <div className="col-md-7 order-md-1 pt-md-4 pt-lg-5">
              <div className="pe-md-4 me-md-2">
                <h2 className="h4">Data Architecture</h2>              
                <p className=" d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">Construct data architectures that support your long-term data strategy, discover new methods for collecting quality data, and efficiently utilize your data.
                </p>
                <Link to='/data-architecture' className="btn btn-outline-primary">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>






      {/* Testimonials */}
      <Testimonial />

      {/* Brands (Carousel) */}
      <Brands />

      {/* Sign up CTA */}
      <SignUp />

      {/* Footer */}
      <Footer />
    </main> 
   
    </>
  )
}

export default Expertise