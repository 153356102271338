import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore , {Pagination , Navigation} from 'swiper';
import 'swiper/css';


SwiperCore.use([Pagination , Navigation])

function testimonial() {
  return (
    <section className="py-5">
    <div className="container py-2 py-md-3 py-lg-4 py-xl-5">
      <div className="row row-cols-1 row-cols-md-2 gx-3 gx-lg-4">
        <div className="col-md-6 mb-3 mb-md-0 ">
          <div className="card bg-dark h-100 p-xl-4 ">
            <div className="card-body d-flex align-items-center">
              <h2 className="display-4 text-light px-2">What Our Clients say about us</h2>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card border-0 shadow-sm p-4 p-xxl-5">
            {/* Slider prev/next buttons + Quotation mark */}
            <div className="d-flex justify-content-end pb-4 mb-2">
              <div className="d-flex">
                <button type="button" id="testimonials-prev" className="btn btn-prev btn-icon btn-sm me-2">
                  <i className="bx bx-chevron-left"></i>
                </button>
                <button type="button" id="testimonials-next" className="btn btn-next btn-icon btn-sm ms-2">
                  <i className="bx bx-chevron-right"></i>
                </button>
              </div>
            </div>

            {/* Slider */}
            <Swiper className="swiper mx-0 mb-md-n2 mb-xxl-n3" 
             spaceBetween = {24}
             loop= {true}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              pagination= {{ clickable: true }}
              navigation = {{
                prevEl : "#testimonials-prev",
                nextEl : "#testimonials-next",
              }}
            >
              <div className="swiper-wrapper">

                {/* Item */}
                <SwiperSlide className="swiper-slide h-auto">
                  <figure className="card h-100 position-relative border-0 bg-transparent">
                    <span className="mb-4"><img src="../assets/img/testimonial/Testimonial-1.png" alt='' /></span>
                    <blockquote className="card-body p-0 mb-0">
                      <p className="fs-lg mb-0">Cogmatic have been proactive in their approach. They have really added value to what we've worked on together.</p>
                    </blockquote>
                    <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                      <div className="">
                        <h6 className="fw-semibold lh-base mb-0">Greame Smith </h6>
                        <span className="fs-sm text-muted">MD, Cyan Group</span>
                      </div>
                    </figcaption>
                    <div className="card-footer border-0 text-nowrap pt-0">
                      <i className="bx bxs-star text-warning"></i>
                      <i className="bx bxs-star text-warning"></i>
                      <i className="bx bxs-star text-warning"></i>
                      <i className="bx bxs-star text-warning"></i>
                      <i className="bx bxs-star text-warning"></i>
                    </div>
                  </figure>
                </SwiperSlide>

                {/* Item */}
                <SwiperSlide className="swiper-slide h-auto">
                  <figure className="card h-100 position-relative border-0 bg-transparent">
                    <span className="mb-4"><img src="../assets/img/testimonial/Testimonial-2.png" alt=''/></span>
                    <blockquote className="card-body p-0 mb-0">
                      <p className="fs-lg mb-0">The team’s knowledge is, in my opinion, second to none and cannot be ignored. They take the time to get to understand your project and feed back strong recommendations. </p>
                    </blockquote>
                    <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                      <div className="">
                        <h6 className="fw-semibold lh-base mb-0">Peter Melkowski </h6>
                        <span className="fs-sm text-muted">PS</span>
                      </div>
                    </figcaption>
                    <div className="card-footer border-0 text-nowrap pt-0">
                      <i className="bx bxs-star text-warning"></i>
                      <i className="bx bxs-star text-warning"></i>
                      <i className="bx bxs-star text-warning"></i>
                      <i className="bx bxs-star text-warning"></i>
                      <i className="bx bxs-star text-warning"></i>
                    </div>
                  </figure>
                </SwiperSlide>
              </div>

              {/* Pagination (bullets) */}
              <div className="swiper-pagination position-relative mt-5"></div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default testimonial