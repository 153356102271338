import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'



const navbar = (props) => {
    
  return (
   
    <header className={`header navbar navbar-expand-lg navbar-${ props.skin.navSkin }  position-absolute navar-sticky`}>
        <div className="container px-3 ">
        <NavLink to='/' className="navbar-brand pe-3" >
            <img src={`${props.skin.logo}`} width="100%" alt="" />
        </NavLink>
        <div id="navbarNav" className={`offcanvas offcanvas-end bg-${props.skin.sideNav}`}>
            <div className={`offcanvas-header border-bottom border-${props.skin.sideNavBorder}`}>
            <h5 className={`offcanvas-title text-${ props.skin.sideText}`}>Menu</h5>
            <button type="button" className={` btn-close btn-close-${ props.skin.closeBtn}`} data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body mx-lg-auto ">
            <nav> 
                <ul className="navbar-nav mb-2 mb-lg-0">

                    <li className="nav-item">
                    <NavLink to='/home' className="nav-link" >Home</NavLink>
                    </li>
                    <li className="nav-item dropdown">
                        <NavLink to='product' className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Products</NavLink>
                        <ul className={`dropdown-menu dropdown-menu-${props.skin.dropSkin}`}>
                            <li>
                                <NavLink to='/orbix' className="dropdown-item" >Orbix</NavLink>
                            </li>
                            <li>
                                <NavLink to='/mindforge' className="dropdown-item" >MindForge</NavLink>
                            </li>
                           
                        </ul>   
                    </li>
                    <li className="nav-item ">
                    <NavLink to='/expertise' className="nav-link">Expertise</NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink to='/about-us' className="nav-link">About Us</NavLink>
                    </li>
                </ul>
            </nav>
            
            </div>
             <div className={`offcanvas-header border-top border-${props.skin.sideNavBorder}`}>
            <Link to='contact-us' className={ `btn btn-primary w-100`}>Schedule a Call</Link>
            </div>
        </div>
        <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <Link to='/contact-us' className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex">Schedule a Call</Link>
        </div>
    </header>
  )
}

export default navbar