import React from 'react'
import { Link } from 'react-router-dom'

function signup() {
 
  return (
    <section className="container">
        <div className="bg-secondary border border-light rounded-3 py-5 px-4 mt-lg-5 mt-md-4 px-sm-0 zindex-5 position-relative">
          <div className="row justify-content-center py-sm-2 py-lg-5">
            <div className="col-xl-7 col-lg-7 col-md-8 col-sm-10 text-center">
              <h2 className="h1  mb-4" style={{color: "#44465A"}}>Want to discuss your project idea?</h2>
              <p className="fs-lg opacity-70 pb-4 mb-3" style={{color: "#2D304A"}} >Discover how machine learning-powered AI can benefit your business.<br />
              Estimate the budget for your project. Learn more about our expertise and experience.</p>
              <Link to='/contact-us' className="btn btn-primary btn-sm fs-sm rounded  d-lg-inline-flex">Schedule a Call</Link>
            </div>
          </div>
        </div>
    </section>
  )
}

export default signup