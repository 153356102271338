import React from 'react';
import './BackToTopButton.component.css'
import { useEffect , useState } from 'react';


function BackToTopButton() {
    const [backToTopButton , setBackToTopButton] = useState(false);

    useEffect(() =>{
        window.addEventListener("scroll" , () => {
            if(window.scrollY > 200){
                setBackToTopButton(true)
            }else{
                setBackToTopButton(false)
            }
        })

    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top : 0,
            behavior: 'smooth'
        })
    }
  return (
    <div>
        {backToTopButton && (
            <button onClick={scrollUp} style={{
                position : 'fixed',
                justifyContent : 'center',
                alignItems : 'center',
                bottom : "40px", 
                right : "40px",
                height : " 2.75rem",
                width : " 2.75rem",
                fontSize : "1.5rem",
                borderRadius : '50px',
                backgroundColor : 'rgba(19, 16, 34, 0.25)',
                borderColor : 'transparent' 
               
                
            }}>

            {/* <span className="text-muted fs-sm me-2" style={{
                position : 'absolute',
                top : '50%',
                right : '100%',
                opacity : '0',
                visibility : 'hidden' 
            }}>Top</span> */}
           
            <i className="bx bx-chevron-up" style={{
                position : 'relative',
                color : 'white' 
            }}></i>  
            </button>
           
        )}
    </div>
  )
}

export default BackToTopButton