import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore , {Pagination} from 'swiper';
import 'swiper/css';


SwiperCore.use([Pagination])


function brands() {
  return (
    <section className="container my-n2 my-md-0 pb-5">
    <Swiper className="swiper mx-n2"
      slidesPerView = {2}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      breakpoints={{
            500: {
            spaceBetween : 10,
            slidesPerView: 3,
            },

            800: {
            slidesPerView: 4,
            },

            1200:{
            slidesPerView: 4,
            spaceBetween : 100
            },
        }}
      >
      <div className="swiper-wrapper d-flex justify-content-between ">

        {/* Item */}
        <SwiperSlide className="swiper-slide py-3">
          <div className="card card-body card-hover px-2 mx-2 img-size">
            <img src="../assets/img/brand/aws.png" className="d-block mx-auto my-2" alt="Brand" />
          </div>
        </SwiperSlide>

        {/* Item */}
        <SwiperSlide className="swiper-slide py-3">
          <div className="card card-body card-hover justify-content-center px-2 mx-2 img-size  ">
            <img src="../assets/img/brand/google.png" className="d-block mx-auto my-2" width="100px" alt="Brand" />
          </div>
        </SwiperSlide>

        {/* Item */}
        <SwiperSlide className="swiper-slide py-3">
          <div className="card card-body card-hover px-2 mx-2 justify-content-center img-size">
            <img src="../assets/img/brand/microsoft-partner.png" className="d-block mx-auto my-2" width="100px"  alt="Brand" />
          </div>
        </SwiperSlide>

        {/* Item */}
        {/*<div className="swiper-slide py-3">
          <a href="#" className="card card-body card-hover px-2 mx-2 justify-content-center img-size">
            <img src="assets/img/rae.png" className="d-block mx-auto my-2" width="136px" alt="Brand">
          </a>
        </div> */}

        {/* Item */}
        <SwiperSlide className="swiper-slide py-3">
          <div className="card card-body card-hover px-2 mx-2 justify-content-center img-size">
            <img src="../assets/img/brand/rhu.png" className="d-block mx-auto my-2" width="146px"  alt="Brand" />
          </div>
        </SwiperSlide>
      </div>
    </Swiper>
    {/* Pagination */}
   
  </section>
  
  )
}

export default brands