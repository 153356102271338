import React from 'react'
import Testimonial from '../../../components/testimonial/testimonial.component'
import Brands from '../../../components/brands/brands.componenet'
import SignUp from '../../../components/signup/signup.component';
import Footer from '../../../components/footer/footer.componenet';


function Orbix() {
  return (
    <>
        {/* Page wrapper for sticky footer */}
    <main className="page-wrapper overflow-hidden">

    {/* Hero */}
    <section className="position-relative pt-md-3 pt-lg-5  bg-secondary ">
        <div className="container position-relative zindex-5 pt-5">
        <div className="row mt-lg-4 pt-lg-5 mt-2  align-items-center">
            <div className="col-xl-4 col-lg-5 text-center text-lg-center pb-3 pb-md-4 pb-lg-0 ">
            
            <img src="../assets/img/logo/logo_black_colour.png" className='cs-hero-img' width="60%" alt=''/>
            </div>
            <div className="col-xl-4 col-lg-6 offset-xl-1 position-relative zindex-5 mb-5 mb-lg-0">
            <div className="card bg-case border-0 shadow-case py-2 p-sm-4 p-lg-5" data-jarallax-element="40" data-disable-parallax-down="lg">
                <div className="card-body p-lg-3">
                <h2 className="text-light pb-1 pb-md-3">Orbix</h2>
                <p className="fs-lg text-light pb-2 pb-md-0 mb-4 mb-md-5">
                    Orbix is a AI powered cloud PACs solution that is enabling innovative functionality and transforming what is possible in the Non-Destructive Testing industry.
                </p>
                <a href="https://orbixnde.com/" target='_blank' rel="noopener noreferrer" className="btn btn-outline-secondary">
                    About Orbix
                    <i className="bx bx-right-arrow-alt lh-1 fs-4 ms-2 me-n2"></i>
                </a>
                </div>
            </div>
            </div>
        </div>
        <div className='d-none d-lg-block' style={{ "marginTop": "-165px"}}></div>
        <div className='row align-items-end'>
            <div className='col-lg-6 d-none d-lg-block orbix-logo-large'>
            <img src="../assets/img/logo/OrbixLogoLarger.png" className="rounded-3" alt="" data-jarallax-element="-40" data-disable-parallax-down="md"/>
            </div>
        </div>
        </div>
        <div className='d-none d-lg-block position-absolute top-0 end-0 w-50 d-flex flex-column ps-3' style={{ "height": "calc(100% - 108px)"}}>
        <div className="w-100 h-100 overflow-hidden bg-position-center bg-repeat-0 bg-size-cover "  style={{"backgroundImage": " url(../assets/img/landingcogmatic/OrbixBg.jpg" ,"borderBottomRadiusLeft": "0.5rem"}}></div>
        </div>
    </section>




{/* Case Study List */}
<section className="pb-3 pb-lg-3 bg-secondary ">
       {/* Item */}
        <div className="container">
          <div className="row pb-5 mb-md-4 mb-lg-5  d-flex align-items-center ">
            <div className=" order-last col-md-6  pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center">
                  <video autoPlay muted loop  width="100%">
                      <source src="assets/video/PROBLEM_UPDATE_V2.mp4"/>
                      This browser does not support HTML5 audio
                  </video>  
            </div>
            <div className="order-first col-md-6  pt-md-4 pt-lg-5" style={{"paddingRight" : "7%"}}>
            
                <h2 className="h4 line">Problem</h2>
                <p className=" d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">
                Industrial radiographic testing (RT) is used to ensure the safety of critical infrastructure and mechanical equipment. The reliability and portability that radiographic testing provides has made it a key component of quality control in industries that must adhere to stringent safety standards, such as the oil and gas, manufacturing, and aerospace industries.</p>
                <p className=" d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">
                The industry currently suffers from major vulnerabilities such as  human fraud or human error. It is common for technicians carrying out these critical tests to make incorrect calls, use poor quality x-ray images, or even commit inspection fraud by purposely shooting fraudulent x-rays leaving critical infrastructure untested and at risk.</p>
                <p className=" d-lg-block pb-3 mb-2 mb-md-3 expertise-p fs-6">
                These vulnerabilities are introducing huge financial, safety, and environmental risks to owner companies, contrtactors, and the general public.</p>
             
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pb-lg-5 pt-5 pt-lg-5">
       {/* Item */}
        <div className="container ">
          <div className="row pb-5 mb-md-4 mb-lg-5  d-flex align-items-center">
            <div className=" order-lg-first order-md-fisrt order-last    col-lg-6 col-md-6 pt-5  pb-1 mb-3 d-flex justify-content-center   ">
              {/* <img src="assets/img/orbix_manage_companies.png " className="position-absolute overflow-hidden "  alt=""/> */}
              <video autoPlay muted loop  width="100%">
                <source src="assets/video/SOLUTION_UPDATED_V2.mp4"/>
                This browser does not support HTML5 audio
              </video>  
            </div>
            <div className="order-lg-last order-md-last order-first   col-lg-6 col-md-6 pt-md-4 pt-lg-5">
            <div className="pe-md-4 me-md-2 px-md-5 d-grid ">
              <h2 className="h4 line  ">Solution</h2>
                <img src="../assets/img/logo_black_colour.png" width="30%" alt=''/>
              <p className=" d-lg-block   mb-2 mb-md-3 expertise-p fs-6">Orbix cloud PACs AI-powered remote radiography platform enables unparalleled oversight, increased operational efficiency, and impactful innovative AI functionality.</p>
              <p className=" d-lg-block pb-3 pt-3 mb-2 mb-md-3 expertise-p fs-6">Orbix fraud detection module is a human-in-the-loop system that takes the latest in AI and Computer Vision and puts it directly in the hands of inspectors so they can for the first time ever accurately detect the submission of fraudulent x-rays.</p>
            </div>
            <a href="https://orbixnde.com/" target="_blank" rel="noopener noreferrer" className="btn btn-primary mx-md-5 ">About Orbix  <i className="bx bx-right-arrow-alt lh-1 fs-4 ms-2 me-n2"></i></a>
          </div>

            </div>
           
          </div>
       
      </section>

      <section className="pb-5 pb-lg-5 pt-5 pt-lg-5 bg-secondary">
        {/* Item */}
        <div className="container">
          <div className="row pb-5 mb-md-4 mb-lg-5 pt-5 d-flex align-items-center ">
            <div className=" order-last col-lg-6 col-md-7  pb-1 mb-3 pb-md-0 mb-md-0 d-flex justify-content-center ">
              {/* <img src="../assets/img/results.png" className="" width="700" alt=""/> */}
              <video autoPlay muted loop  width="100%">
                <source src="assets/video/RESULT_V1.mp4"/>
                This browser does not support HTML5 audio
              </video> 
            </div>
            <div className="order-first col-lg-6 col-md-5  pt-md-4 pt-lg-5">
              <div className="pe-md-4 me-md-2">
                <h2 className="h4 mx-4 line line-pos">Result</h2>
                <ul className="list-unstyled pb-4 mb-0  ">
                  <li className="d-flex align-items-center pb-1 mb-2">
                    <i className="bx bx-check-circle fs-xl text-primary me-2"></i>
                    Orbix dramatically increases oversight, and operational efficiency while reducing compliance and safety risks during the construction of critical infrastructure.
                  </li>
                  <li className="d-flex align-items-center pb-1 mb-2">
                    <i className="bx bx-check-circle fs-xl text-primary me-2"></i>
                    Orbix detects x-rays of duplicate welds regardless of how a technician manipulates the images with 97% accuracy.
                  </li>
                  <li className="d-flex align-items-center pb-1 mb-2">
                    <i className="bx bx-check-circle fs-xl text-primary me-2"></i>
                    Orbix detects individual x-ray shots that have insufficient or anomalous overlap (fraudulent shots) with 98% accuracy.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


{/* Testimonials */}
<Testimonial />

{/* Brands (Carousel) */}
<Brands />

{/* Sign up CTA */}
<SignUp />

{/* Footer */}
<Footer />
</main>

</>
  )
}

export default Orbix