import React  from 'react';
import { useLocation  } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/navbar.component';
import About from './routes/about/about.component';
import Contact from './routes/contact/contact.component';
import Expertise from './routes/expertise/expertise.component';
import ExpertiseInner1 from './routes/expertise/inner-expertise/full-stack-development.component';
import ExpertiseInner2 from './routes/expertise/inner-expertise/artificial-intelligence.component';
import ExpertiseInner3 from './routes/expertise/inner-expertise/machine-learning.component';
import ExpertiseInner4 from './routes/expertise/inner-expertise/natural-language-processing.component';
import ExpertiseInner5 from './routes/expertise/inner-expertise/computer-vision.component';
import ExpertiseInner6 from './routes/expertise/inner-expertise/predictive-analytics.component';
import ExpertiseInner7 from './routes/expertise/inner-expertise/data-architecture.component';
import Home from './routes/home/home.component';
import Orbix from './routes/products/products-names/orbix.component';
import MindForge from './routes/products/products-names/mindforge.component';
import ScrollToTop from './components/scrollToTop';




function App() {
 
  const location = useLocation();
  var skin = {navSkin:"dark", logo: "../assets/img/logo/CogmaticLogo.png" , dropSkin:"dark" , sideNav: "dark", sideText: "light" , sideNavBorder:"light" , closeBtn:"white"};
  
  if(location.pathname === "/orbix"){
     skin.navSkin = "light";
     skin.logo = "../assets/img/logo/CogmaticLogoDark.png";
     skin.dropSkin= "light";
     skin.sideNav = "light";
     skin.sideText = "dark";
     skin.sideNavBorder= "dark";
     skin.closeBtn="dark";
     
  }
 
  return (
    <>      
    {/* Navbar */}
    <Navbar  skin={skin} />
    <ScrollToTop />
    <Routes>
      
        <Route path='/' element= {<Home />} />
        <Route path='/home' element= {<Home />} />
        <Route path='orbix' element= {<Orbix />} />
        <Route path='mindforge' element= {<MindForge />} />

        <Route path='expertise' element= {<Expertise />} />

        <Route path='expertise/full-stack-development' element= {<ExpertiseInner1/>} />
        <Route path='artificial-intelligence' element= {<ExpertiseInner2/>} />
        <Route path='machine-learning' element= {<ExpertiseInner3/>} />
        <Route path='natural-language-processing' element= {<ExpertiseInner4/>} />
        <Route path='computer-vision' element= {<ExpertiseInner5/>} />
        <Route path='predictive-analytics' element= {<ExpertiseInner6/>} />
        <Route path='data-architecture' element= {<ExpertiseInner7/>} />



      
        

        <Route path='about-us' element= {<About />} />
        <Route path='contact-us' element= {<Contact/>} />
        
    </Routes>

    </> 
  );
}

export default App;
