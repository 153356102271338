import React from 'react';
import BackToTopButton from '../totopbtn/BackToTopButton';

function footer() {
  return (
    
    <footer className="footer bg-dark dark-mode pt-5 pb-4 pb-lg-5 zindex-4 overflow-hidden position-relative " style={{marginTop: "-7%"}} >
    <img src="assets/img/expertise/cog-img-3.png" className=" position-absolute bg-size-cover footer-vector d-none d-lg-block" min-width="30%" alt='' />
    <div className="container text-center pt-lg-3">
      <div className="navbar-brand justify-content-center text-dark pb-lg-2" style={{paddingTop: "7%"}}>
        <a href="index.html" className="navbar-brand pe-3">
          <img src="assets/img/logo/CogmaticLogo.png" width="100%" alt="Cogmatic" />
        </a>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-2 col-sm-2"></div>
        <div className=" d-flex col-lg-6 col-md-8 col-sm-8 justify-content-center ">
           <p className="fs-sm pb-lg-2 text-light ">
            Cogmatic develops bespoke AI Solutions and Enterprise Software. We are experts at bringing custom machine learning solutions out of the lab and into the real world.
           </p>
        </div>
       <div className="d-flex justify-content-center mb-lg-2">
          <a href="#/" className="btn btn-icon btn-secondary btn-linkedin mx-2"  style={{borderRadius: "unset"}}>
            <i className="bx bxl-linkedin"></i>
          </a>
          <a href="#/" className="btn btn-icon btn-secondary btn-twitter mx-2"  style={{borderRadius: "unset"}}>
            <i className="bx bxl-twitter"></i>
          </a>
        </div>
        <p className="fs-sm  pt-3  text-light ">Terms & Conditions Privacy Policy</p>
        <p className=" pb-lg-2 text-light "  style={{fontSize: "12px"}}>Copyrights 2022 © Cogmatic. All Rights Reserved.</p>
      </div>
    </div>
    <BackToTopButton/>

   
  </footer>

  

  )
}

export default footer